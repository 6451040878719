<template>
  <div class="data-regie">
    <div class="box-all-filter mb-5 mt-3">
      <h5 class="title">Informations de régie</h5>
    </div>

    <!-- <div class="body-box-setting">
      <div class="table-rapport-style w-100-p"> -->
    <form @submit.prevent="handelUpdateData" v-if="getRegieToUpdate">
      <div class="body">
        <div class="group-content">
          <div class="label-box-style w-1">
            <span class="title-tabel">Nom</span>
          </div>
          <b-form-input
            autocomplete="off"
            id="Nom"
            v-model="getRegieToUpdate.name"
            disabled
          ></b-form-input>
        </div>
        <div class="group-content">
          <div class="label-box-style w-1">
            <span class="title-tabel">E-mail</span>
          </div>
          <b-form-input
            autocomplete="off"
            id="email"
            v-model="getRegieToUpdate.email"
            type="email"
          ></b-form-input>
        </div>
      </div>
      <div class="body">
        <div class="group-content">
          <div class="label-box-style w-1">
            <span class="title-tabel">Adresse</span>
          </div>
          <b-form-input
            autocomplete="off"
            id="adresse"
            v-model="getRegieToUpdate.adress"
          ></b-form-input>
        </div>
        <div class="group-content">
          <div class="label-box-style w-1">
            <span class="title-tabel">Code Postal</span>
          </div>
          <b-form-input
            autocomplete="off"
            id="code_postal"
            v-model="getRegieToUpdate.code_postal"
          ></b-form-input>
        </div>
      </div>
      <div class="body">
        <div class="group-content">
          <div class="label-box-style w-1">
            <span class="title-tabel">Ville</span>
          </div>
          <b-form-input
            autocomplete="off"
            id="ville"
            v-model="getRegieToUpdate.ville"
          ></b-form-input>
        </div>
        <div class="group-content">
          <div class="label-box-style w-1">
            <span class="title-tabel">Phone</span>
          </div>
          <b-form-input
            autocomplete="off"
            id="phone"
            v-model="getRegieToUpdate.phone"
            type="tel"
          ></b-form-input>
        </div>
      </div>
      <div class="action">
        <b-button
          type="submit"
          class="button-succes-style ml-5 mt-3"
          size="sm"
          variant="success"
        >
          Valider
          <div v-if="getLoadingUpdateInfoRegie" class="chargement">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </b-button>
      </div>
      <div v-if="success" class="success">Modifié</div>
      <div v-if="getErrorInUpdateRegie" class="error">
        <ul v-if="Array.isArray(getErrorInUpdateRegie)">
          <li
            v-for="(error, index) in getErrorInUpdateRegie"
            :key="'error' + index"
          >
            {{ error }}
          </li>
        </ul>
        <span v-else>{{ getErrorInUpdateRegie }}</span>
      </div>
    </form>
    <!-- </div>
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      success: false
    };
  },
  computed: {
    ...mapGetters([
      'getLoadingUpdateInfoRegie',
      'getRegieInfo',
      'getErrorInUpdateRegie'
    ]),
    getRegieToUpdate() {
      return { ...this.getRegieInfo };
    }
  },

  methods: {
    ...mapActions(['fetchRegieInfo', 'editRegieInfo']),
    async handelUpdateData() {
      this.success = false;
      const responce = await this.editRegieInfo(this.getRegieToUpdate);
      if (responce) {
        this.success = true;
      }
    }
  },
  mounted() {
    this.success = false;
    this.fetchRegieInfo();
  }
};
</script>

<style lang="scss" scoped>
.data-regie {
  form {
    margin-top: 2%;
    .body {
      margin: 10px auto;
      display: flex;
      .group-content {
        width: 40%;
        margin: 0px auto;
        margin-top: 15px;
        display: flex;
        .label-box-style {
          padding: 8px;
          height: 35px;
        }
        label {
          display: block;
          color: #4e4e4e;
          margin-bottom: 6px;
          font-size: small;
          font-weight: bold;
        }
        input {
          font: inherit;
          width: 100%;
          padding: 10px;
          box-sizing: border-box;
          border: 1px solid #ddd;
          border-radius: 0px 17px 17px 0px;
          font-size: 14px;
        }
      }
    }
    .action {
      position: relative;
      display: flex;
      justify-content: flex-end;
      margin-right: 3%;
      button {
        padding: 8px 20px;
      }
    }
    .error {
      color: red;
      font-size: 14px;
      text-align: center;
    }
    .success {
      color: green;
      font-size: 14px;
      text-align: center;
    }
  }
}
</style>
